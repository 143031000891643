import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  annulationSignaturePrescription,
  getPrescriptions,
  getPrescriptionsASigner,
} from "../../api/prescriptions.api";
import { selectUtilisateur } from "../../redux/utilisateur/utilisateur.slice";
import type { IPrescription } from "../../interfaces/prescription.interface";
import type { Identite } from "../../interfaces/utilisateur.interface";
import Layout from "../../components/templates/Layout";
import PrescriptionProfil from "../../components/organisms/dashboard/PrescriptionProfil";
import TablePrescriptions, {
  PrescriptionsColumn,
} from "../../components/organisms/table/TablePrescriptions";
import {
  Content,
  Header,
  Wrapper,
  WrapperContent,
  WrapperPrescriptions,
  WrapperTitle,
  WrapperTable,
  WrapperElem,
  WrapperContentLeft,
} from "./index.elements";
import FilterPrescriptions from "../../components/molecules/FilterPrescriptions/FilterPrescriptions";
import { useCallback } from "react";
import SignAllButton from "../../components/molecules/SignAllButton";
import config from "../../config";
import { useLocation } from "react-router-dom";
import TextIconEnAttente from "../../components/atoms/text/TextIconEnAttente";
import TextIconASigner from "../../components/atoms/text/TextIconASigner";
import TablePrescriptionsASigner from "../../components/organisms/table/TablePrescriptionsASigner";
import { SelectPatientPrescription } from "../../components/molecules/FilterPrescriptions/components/SelectPatientPrescription";
import { AddPrescriptionButton } from "../../components/molecules/AddPrescriptionButton";
import { useFiltersPrescriptions } from "../../components/molecules/FilterPrescriptions/hooks/useFiltersPrescriptions";
import { FormSelectWrapperNeutral } from "../../components/molecules/header/HeaderNewPrescription";
import { Tooltip } from "antd";

const contentVariants = {
  initial: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

const transitionDuraction = 0.5;

export default function IndexView() {
  // TODO use position wrapper to get better page transition
  // const [positionWrapper, setPositionWrapper] = useState(110);
  const router: any = useSelector((state: any) => state.router);
  const refMainWrapper = useRef<HTMLInputElement>(null);

  const headerVariants = {
    initial: { y: 200 },
    enter: { y: 0 },
    exit:
      router.location.pathname !== "/utilisateur/connexion"
        ? { y: 400, height: "110px" }
        : { opacity: 0 },
  };

  const wrapperVariants = {
    initial: { top: 110, height: "100vh" },
    enter: { top: 510, height: "auto" },
    exit:
      router.location.pathname !== "/utilisateur/connexion"
        ? { top: 110, height: "100vh" }
        : { opacity: 0 },
  };

  const [prescriptions, setPrescriptions] = useState<IPrescription[]>([]);
  const [listRenouvellement, setListRenouvellement] =
    useState<PrescriptionsColumn[]>();
  const [selectedIPKPrescriptions, getSelectedPrescriptions] = useState<any[]>(
    []
  );
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [aSigner, setPrescriptionsASigner] = useState<IPrescription[]>([]);

  /*@ AJOUT TABLEAU SIGNATURE MULTIPLE*/
  // const [prescriptionsASigner, setPrescptionsASigner] = useState<IPrescription[]>([]);

  // Menu pour selection dashboard/panier
  const menu = ["Renouvellements en attente", "Prêt pour signature"];
  const [menuId, setSelectedDashboard] = useState<number>(0); // selecteur menu dashboard/panier

  const selectedTable = (i: number) => {
    setSelectedDashboard(i);
  };

  const getMenuId = () => {
    return menuId;
  };
  // GET IDENTITY
  const identity = useSelector(selectUtilisateur);
  const errorMessages = {
    genericDescription:
      "Nous rencontrons une erreur de chargement de vos informations, veuillez contacter votre administrateur",
    personnalInformations: "Echec du chargement de vos informations",
    renouvellements: "Echec du chargement du tableau des renouvellements",
    synthesePatients: "Echec du chargement de la synthèse des patients",
    syntheseRenouvellements:
      "Echec du chargement de la synthèse des renouvellements",
  } as const;

  // GET PRESCRIPTIONS FROM IDENTITY LE DASHBOARD (RENOUVELLEMENTS EN ATTENTE)
  const getPrescriptionsFromIdentity = useCallback(
    async (identity: Identite) => {
      try {
        const prescriptions = await getPrescriptions(identity.iFKAnnuaire!); // FIXME: iFKAnnuaire!
        setPrescriptions(prescriptions);
      } catch (error) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorMessages.genericDescription, errorMessages.renouvellements]
  );

  // GET PRESCRIPTIONS FROM IDENTITY POUR LE DASHBOARD (PRETS A SIGNER)
  const getPrescriptionsASignerFromIdentity = useCallback(
    async (identity: Identite) => {
      try {
        const prescriptionsASigner = await getPrescriptionsASigner(
          identity.iFKAnnuaire!
        ); // FIXME: iFKAnnuaire!
        setPrescriptions(prescriptionsASigner);
      } catch (error) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorMessages.genericDescription, errorMessages.renouvellements]
  );

  const loadTablePrescriptions = () => {
    if (identity) {
      if (menuId === 0) {
        getPrescriptionsFromIdentity(identity);
      } else if (menuId === 1) {
        getPrescriptionsASignerFromIdentity(identity);
      }
    }
  };

  // Récupération des variables de délai refresh
  const oodriveDelaiRefreshSignatureEchoue =
    config.oodriveDelaiRefreshSignatureEchoue;
  const oodriveDelaiPremierRefreshFinalisation =
    config.oodriveDelaiPremierRefreshFinalisation;

  // Etat signature - Variables
  const location = useLocation(); // récupération url
  useEffect(() => {
    if (config.oodriveSignatureActivated) {
      // Premier refresh après 30 secondes si annulation (signature simple)
      // Recherche si url contient l'id du bundle annulé, auquel cas, on met la prescription à l'état initial
      const params = location["search"];
      // if (identity) {
      //   if(menuId === 1) getPrescriptionsASignerFromIdentity(identity);
      //   if(menuId === 0) getPrescriptionsFromIdentity(identity);
      // }
      loadTablePrescriptions();
      if (params.indexOf("bundle_id") > 0) {
        const bundleId = params.substring(
          params.indexOf("=") + 1,
          params.indexOf("&")
        );
        annulationSignaturePrescription(
          identity?.iFKAnnuaire as number,
          bundleId as unknown as number,
          null
        );
        // Premier refresh annulation signatures oodrive
        // setTimeout(() => {

        //   loadTablePrescriptions();
        // }, oodriveDelaiPremierRefreshAnnulation);
      }

      // Premier refresh finalisation signatures oodrive
      else if (params.indexOf("oodrive=ok") > 0) {
        setTimeout(() => {
          loadTablePrescriptions();
        }, oodriveDelaiPremierRefreshFinalisation);
      }

      // check les états de prescription après 15 min
      setTimeout(() => {
        loadTablePrescriptions();
      }, oodriveDelaiRefreshSignatureEchoue);
    } // signature classique - sans oodrive
    else {
      const params = location["search"];
      if (config.signatureClassiqueMultiple) {
        loadTablePrescriptions();
        if (params.indexOf("normal=true") > 0) {
          // setTimeout(() => {
          //   loadTablePrescriptions();
          // }, 5000);
        }
      } else {
        if (identity) {
          getPrescriptionsFromIdentity(identity);
        }
        if (params.indexOf("normal=true") > 0) {
          // setTimeout(() => {
          //   if(identity){
          //     getPrescriptionsFromIdentity(identity);
          //   }
          // }, 5000);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getPrescriptionsFromIdentity,
    getPrescriptionsASignerFromIdentity,
    identity,
    menuId,
  ]);

  // Controler de filtres de prescription
  const controlerFiltersInput = {
    prescriptions: prescriptions,
    setPrescriptions: setPrescriptions,
    setListRenouvellement: setListRenouvellement,
  };

  const controlerFilters = useFiltersPrescriptions(controlerFiltersInput);

  return (
    <Layout
      header={
        <Header
          initial="initial"
          animate="enter"
          exit="exit"
          variants={headerVariants}
          transition={{ ease: "easeInOut", duration: transitionDuraction }}
        >
          <WrapperPrescriptions>
            <PrescriptionProfil />
          </WrapperPrescriptions>
        </Header>
      }
      main={
        <Wrapper
          id="main"
          initial="initial"
          animate="enter"
          exit="exit"
          className="WRAPPER"
          variants={wrapperVariants}
          transition={{ ease: "easeInOut", duration: transitionDuraction }}
          ref={refMainWrapper}
        >
          <WrapperContent>
            <Content
              initial="initial"
              animate="enter"
              exit="exit"
              variants={contentVariants}
              transition={{ ease: "easeInOut", duration: transitionDuraction }}
            >
              <WrapperTitle>
                <WrapperContentLeft>
                  {/*
                    <Tooltip arrowPointAtCenter title="Créer une prescription">
                      <WrapperElem>
                        <AddPrescriptionButton />
                      </WrapperElem>
                    </Tooltip>
      */}

                  <WrapperElem>
                    <TextIconEnAttente
                      label="Renouvellements en attente"
                      icon="tableau"
                      size="medium"
                      color={menuId === 1 ? "grey" : "black"}
                      onClick={() => {
                        selectedTable(0);
                      }}
                    />
                  </WrapperElem>
                  <WrapperElem>
                    {/* dashboard signatures multiples si activé  */}
                    {(config.oodriveSignatureActivated ||
                      config.signatureClassiqueMultiple) && (
                      <TextIconASigner
                        label="Prêt pour signature"
                        icon="pictoPanier"
                        size="medium"
                        color={menuId === 0 ? "grey" : "black"}
                        onClick={() => {
                          selectedTable(1);
                        }}
                      />
                    )}
                  </WrapperElem>
                  <WrapperElem>
                    {prescriptions && getMenuId() === 0 && (
                      <FormSelectWrapperNeutral>
                        <SelectPatientPrescription {...controlerFilters} />
                      </FormSelectWrapperNeutral>
                    )}
                  </WrapperElem>
                </WrapperContentLeft>

                {menuId === 1 && (
                  <SignAllButton
                    selectedIPKPrescriptions={selectedIPKPrescriptions}
                  />
                )}
              </WrapperTitle>
              {prescriptions && (
                <WrapperTable>
                  <FilterPrescriptions {...controlerFilters} />
                  {prescriptions && getMenuId() === 0 && (
                    <TablePrescriptions
                      prescriptions={prescriptions}
                      setPrescriptions={setPrescriptions}
                      listRenouvellement={listRenouvellement}
                      getSelectedPrescriptions={getSelectedPrescriptions}
                      getPrescriptionsFromIdentity={
                        getPrescriptionsFromIdentity
                      }
                    />
                  )}
                  {prescriptions && getMenuId() === 1 && (
                    <TablePrescriptionsASigner
                      prescriptions={aSigner}
                      setPrescriptions={setPrescriptions}
                      listRenouvellement={listRenouvellement}
                      getSelectedPrescriptions={getSelectedPrescriptions}
                      getPrescriptionsASignerFromIdentity={
                        getPrescriptionsASignerFromIdentity
                      }
                      getMenuId={getMenuId}
                    />
                  )}
                </WrapperTable>
              )}
            </Content>
          </WrapperContent>
        </Wrapper>
      }
    />
  );
}
